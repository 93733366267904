<template>
    <div class="choseMembers">
        <div class="contant">
            <div class="search iconfont iconsousuo">
                <el-input placeholder="请输入待选人员名称" v-model="queryCriteria" @change="query"></el-input>
            </div>
            <div class="clearfix createTeamCon">
                <div class="role el_left">
                    <div class="tit">
                        角色
                    </div>
                    <ul>
                        <template v-for="(item, index) in approverRoleName">
                            <li :key="index" :class="{roleSelection:index == currentIndex}" @click="obtainPersonnel(index)">
                                <i class="iconfont iconminsuzhuguanli"></i>
                                <span>{{ item }}</span>
                            </li>
                        </template>
                    </ul>
                </div>
                <div class="transContainer el_left">
                    <div class="trans el_left">
                        <template v-if="isRadio">
                            <div class="tit">
                                待选人员
                            </div>
                            <div class="trans-con">
                                <el-radio-group v-model="radioWaitMembers">
                                    <template v-for="(item, index) in waitMembersData">
                                        <el-radio
                                            :label="item.user_id"
                                            :key="index"
                                        >
                                            {{ item.user_name }}
                                        </el-radio>
                                    </template>
                                </el-radio-group>
                            </div>
                        </template>
                        <template v-else>
                            <div class="tit">
                                <el-checkbox v-model="checkWaitAll" @change="handleWaitAllChange">
                                    待选人员
                                </el-checkbox>
                            </div>
                            <div class="trans-con">
                                <el-checkbox-group v-model="checkedWaitMembers" @change="handleCheckedWaitChange">
                                    <template v-for="item in waitMembersData">
                                        <el-checkbox
                                            :label="item"
                                            :key="item.user_id"
                                        >
                                            {{ item.user_name }}
                                        </el-checkbox>
                                    </template>
                                </el-checkbox-group>
                            </div>
                        </template>
                    </div>
                    <div class="el_left trans-arrow">
                        <div class="buttons">
                            <span class="button-item" @click="transRight">
                                <i class="iconfont iconjiantouyou"></i>
                            </span>
                            <span class="button-item" @click="transLeft">
                                <i class="iconfont iconjiantouzuo"></i>
                            </span>
                        </div>
                    </div>
                    <div class="trans el_left">
                        <template v-if="isRadio">
                            <div class="tit">
                                已选人员
                            </div>
                            <div class="trans-con">
                                <el-radio-group v-model="radioChosedMembers">
                                    <template v-for="(item, index) in chosedMembersData">
                                        <el-radio
                                            :label="item.user_id"
                                            :key="index"
                                        >
                                            {{ item.user_name }}
                                        </el-radio>
                                    </template>
                                </el-radio-group>
                            </div>
                        </template>
                        <template v-else>
                            <div class="tit">
                                <el-checkbox v-model="checkChosedAll" @change="handleChosedAllChange">
                                    已选人员
                                </el-checkbox>
                            </div>
                            <div class="trans-con">
                                <el-checkbox-group v-model="checkedChosedMembers" @change="handleCheckedChosedChange">
                                    <template v-for="item in chosedMembersData">
                                        <el-checkbox
                                            :label="item"
                                            :key="item.user_id"
                                        >
                                            {{ item.user_name }}
                                        </el-checkbox>
                                    </template>
                                </el-checkbox-group>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <span slot="footer" class="footer dialog-footer">
                <el-button type="primary" @click="submitFn">确 定</el-button>
                <el-button @click="handleClose">取 消</el-button>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    // 选择人员
    name: 'chose-members',
    components: {},
    props: {
        isRadio: {
            type: [Boolean, Array, Object, String ],
        },
        businessInstanceId: {
            type: [String],
        },
        specifyRoleData: {
            type: [Object],
        },
    },
    data() {
        return {
            approverRoleId: [],
            approverRoleName: [],
            // 当前角色下标
            currentIndex: 0,
            // 待选-人员
            waitMembersData: [],
            // 待选-已选人员（多选）
            checkedWaitMembers: [],
            // 待选-已选人员（单选）
            radioWaitMembers: '',
            // 待选-全选 
            checkWaitAll: false,
            // 已选-人员
            chosedMembersData: [],
            // 已选-已选人员（多选）
            checkedChosedMembers: [],
            // 已选-已选人员（单选）
            radioChosedMembers: '',
            // 已选-全选 
            checkChosedAll: false,
            // 查询条件
            queryCriteria: '',
        };
    },
    created() {
        if (Object.keys(this.specifyRoleData).length > 0) {
            this.deptType = this.specifyRoleData.SubmitPhaseModel?.DeptType || '';
            this.approverRoleId = this.specifyRoleData.SubmitPhaseModel.ApproverRoleId.split(',');
            this.approverRoleName = this.specifyRoleData.SubmitPhaseModel.ApproverRoleName.split(',');
        }
    },
    mounted() {
        // 初始化第一个角色数据
        this.obtainPersonnel(0);
    },
    watch: {},
    computed: {
    },
    methods: {
        // 获取角色下人员
        obtainPersonnel(index) {
            this.currentIndex = index;
            const roleid = this.approverRoleId[index];
            const parameter = `businessInstanceId=${this.businessInstanceId}&roleId=${roleid}&deptType=${this.deptType}`;
            this.$axios
                .get(`/interfaceApi/basicplatform/simpleflow/processes/getphaseroleuser?${parameter}`)
                .then(res => {
                    this.waitMembersData = res;
                    this.filteringData(this.waitMembersData);
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 条件查询当前角色下人员
        query() {
            const queryResults = [];
            if (this.queryCriteria !== '') {
                this.waitMembersData.forEach(item => {
                    if (item.user_name.indexOf(this.queryCriteria) !== -1) {
                        queryResults.push(item);
                    }
                });
                this.waitMembersData = queryResults;
                this.filteringData(this.waitMembersData);
            } else {
                this.obtainPersonnel(0);
            }
        },
        // 过滤数据，如果只有一条直接选中
        filteringData(data) {
            this.chosedMembersData = [];
            this.radioWaitMembers = '';
            this.radioChosedMembers = '';
            this.checkedWaitMembers = [];
            this.checkedChosedMembers = [];
            if (data.length === 1) {
                if (this.isRadio) {
                    this.radioWaitMembers = data[0].user_id;
                    this.radioChosedMembers = data[0].user_id;
                } else {
                    this.checkedWaitMembers.push(data[0]);
                    this.checkedChosedMembers.push(data[0]);
                }
                this.chosedMembersData = data;
            }
        },
        /* 待选列表选中事件 */
        handleWaitAllChange() {
            this.checkedWaitMembers = this.checkWaitAll ? this.waitMembersData : [];
        },
        handleCheckedWaitChange(value) {
            const checkedCount = value.length;
            this.checkWaitAll = checkedCount === this.waitMembersData.length;
        },
        /* 已选列表选中事件 */
        handleChosedAllChange() {
            this.checkedChosedMembers = this.checkChosedAll ? this.chosedMembersData : [];
        },
        handleCheckedChosedChange(value) {
            const checkedCount = value.length;
            this.checkChosedAll = checkedCount === this.chosedMembersData.length;
        },
        // 向右选取
        transRight() {
            if (this.isRadio) {
                this.waitMembersData.forEach(item => {
                    if (item.user_id === this.radioWaitMembers) {
                        this.chosedMembersData = [];
                        this.chosedMembersData.push(item);
                    }
                });
            } else {
                const newArr = [...this.chosedMembersData, ...this.checkedWaitMembers];
                this.chosedMembersData = Array.from(new Set(newArr));
                this.checkChosedAll = this.checkedChosedMembers.length === this.chosedMembersData.length;
            }
        },
        // 向左选取,删除已选中
        transLeft() {
            if (this.isRadio) {
                this.chosedMembersData = [];
            } else {
                this.chosedMembersData = this.chosedMembersData.filter(item => !this.checkedChosedMembers.some(ele => ele.user_id === item.user_id));
                this.checkChosedAll = this.checkedChosedMembers.length === this.chosedMembersData.length;
            }
        },
        // 确认选择
        submitFn() {
            this.$emit('obtainPersonnel', this.chosedMembersData);
        },
        // 取消选择
        handleClose() {
            this.$emit('deselected');
        },
    },
};
</script>
<style lang="stylus">
.choseMembers
    .contant
        height 5.4rem
        padding 0.2rem
        .search
            width 100%
            height 0.36rem
            position relative
            margin-bottom 0.15rem
            &:before
                position absolute
                right 0.1rem
                font-size 0.2rem
                top 0
                height 0.36rem
                line-height 0.36rem
                color #BBBBBB
                z-index 2
        .createTeamCon
            overflow hidden
            height 4rem
            .role
                width 2.12rem
                height 100%
                border 1px solid #D7D7D7
                margin-right 0.37rem
                .tit
                    height 0.34rem
                    line-height 0.34rem
                    background #ECF1F6
                    margin 0
                    padding-left 0.15rem
                    -webkit-box-sizing border-box
                    box-sizing border-box
                    color #000
                    font-size 0.16rem
                ul
                    height calc(100% - 0.34rem)
                    overflow-x hidden
                    overflow-y auto
                    li
                        font-size 0.14rem
                        line-height 0.34rem
                        padding-left 0.2rem
                        border-bottom 1px solid #F0F0F0
                        i
                            color #979EA7
                            margin-right 0.1rem
                        span
                            color #333333
                        &.roleSelection
                            i,
                            span
                                color #5588F1
                        &:hover
                            background #FBFBFB
            .transContainer
                width calc(100% - 2.5rem)
                height 100%
                .trans
                    height 100%
                    width calc((100% - 0.37rem)/2)
                    border 1px solid #D7D7D7
                    .tit
                        height 0.34rem
                        line-height 0.34rem
                        background #ECF1F6
                        margin 0
                        padding-left 15px
                        -webkit-box-sizing border-box
                        box-sizing border-box
                        color #000
                        font-size 0.16rem
                        .el-checkbox__input
                            margin-top -2px
                        .el-checkbox__label
                            color #000
                            font-size 0.16rem
                .trans-arrow
                    width 0.37rem
                    height 100%
                    padding-top 35%
                    .buttons
                        display inline-block
                        vertical-align middle
                        .button-item
                            width 0.27rem
                            height 0.27rem
                            border 1px solid #dee1e7
                            background #f5f7fa
                            text-align center
                            border-radius 50%
                            display block
                            margin 0 0.05rem
                            display inline-block
                            margin-bottom 0.1rem
                            cursor pointer
                            color #cbcfd6
                            text-align center
                            i
                                font-size 0.16rem
                                line-height 0.27rem
                            &:nth-of-type(1)
                                &:hover
                                    background #409eff
                                    border 1px solid #409eff
                                    color #fff
                            &:nth-of-type(2)
                                &:hover
                                    background #D40004
                                    border 1px solid #D40004
                                    color #fff
                .trans-con
                    height calc(100% - 34px)
                    overflow-y auto
                    overflow-x hidden
                    width 100%
                    .el-radio-group
                    .el-checkbox-group
                        font-size 0.14rem
                        line-height 0.34rem
                        padding-left 0.15rem
                        width 100%
                        .el-radio__input
                            margin-top -2px
                    .el-checkbox,
                    .el-radio
                        width calc(100% - 0.3rem)
        .transPagination
            text-align center
            >div
                display inline-block
        .footer
            display inline-block
            width 100%
            text-align center
            margin-top 0.2rem
            button
                padding 7px 9px
</style>